/* Coverage:
 Statements: 29%
 Branches: 80%
 Functions: 45%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import { Button, Card, CardBody } from "@heroui/react"
import React from 'react'
import { useNavigate, useParams, useRouteError } from 'react-router'
import useStrings from '~/hooks/useStrings'
import { sentryService } from '~/lib/sentry'
interface ErrorDisplayProps {
  message?: string
  t: (text: string) => string
  lang: string | undefined
}
function ErrorDisplay({ message, t, lang }: ErrorDisplayProps) {
  const navigate = useNavigate()

  const handleNavigate = async () => {
    // Reset the error boundary by navigating and replacing the current entry
    await navigate(`/${lang || 'en'}`, { replace: true })
    window.location.reload()
  }

  return (
    <main className="w-full min-h-screen  sm:px-4 sm:py-8">
      <div className="w-full h-full flex items-center justify-center" role="alert" aria-live="assertive">
        <Card className="flex flex-col mb-4 p-6 max-w-lg w-full">
          <CardBody className="relative text-center" aria-labelledby="error-heading">
            <h1 id="error-heading" className="text-3xl font-bold text-red-500" tabIndex={-1}>
              {message || t('Something went wrong')}
            </h1>
            <p className="mt-4 text-secondary" role="status">
              {message || t('We have been notified and are working on fixing it.')}
            </p>
            <Button
              onPress={handleNavigate}
              color="primary"
              className="mt-16 w-fit mx-auto"
              aria-label={t('Return to the Home Page')}
            >
              {t('Return to the Home Page')}
            </Button>
          </CardBody>
        </Card>
      </div>
    </main>
  )
}
interface Props {
  children: React.ReactNode
  message?: string
}
export function ComponentErrorBoundaryWrapper(props: Props) {
  const { t } = useStrings()
  const { lang } = useParams()
  return <ComponentErrorBoundaryClass {...props} t={t} lang={lang} />
}
interface ClassProps extends Props {
  t: (text: string) => string
  lang: string | undefined
}
class ComponentErrorBoundaryClass extends React.Component<
  ClassProps,
  {
    hasError: boolean
    error: null
  }
> {
  constructor(props: ClassProps) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
    }
  }
  static getDerivedStateFromError(error: Error) {
    console.error('Error caught in boundary:', error)
    return {
      hasError: true,
      error,
    }
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ComponentErrorBoundary caught an error:', error, errorInfo)

    sentryService.logError(error, true, {
      source: 'ComponentErrorBoundary',
      errorInfo: errorInfo,
    })
  }
  handleReset = () => {
    this.setState({
      hasError: false,
      error: null,
    })
  }
  render() {
    const { t, lang } = this.props

    if (this.state.hasError) {
      return <ErrorDisplay t={t} lang={lang} />
    }

    return this.props.children
  }
}
interface RouteError {
  status?: number
  statusText?: string
  message?: string
  data?: unknown
}
export function RouteErrorBoundary() {
  const error = useRouteError() as RouteError
  const { t } = useStrings()
  const { lang } = useParams()
  sentryService.logError(error, true, {
    source: 'RouteErrorBoundary',
    status: error.status,
    statusText: error.statusText,
    message: error.message,
    data: error.data,
  })
  const getErrorMessage = () => {
    if (error.status === 404) {
      return t('Page not found')
    }
    if (error.status === 401) {
      return t('Unauthorized access')
    }
    if (error.status === 500) {
      return t('Server error')
    }
    return t('An unknown error occurred')
  }
  return <ErrorDisplay message={getErrorMessage()} t={t} lang={lang} />
}
